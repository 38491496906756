








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Submenu } from 'element-ui';

@Component
export default class InsSearchResult extends Vue {
  resultList: object[] = []; // cms内容列表
  currentPage: number = 1; // 当前页
  pageSize: number = 100; // 每页显示条目个数
  totalRecord: number = 0;// 总条目数
  heightArr: number[]=[];
  loadImageCount:number = 0;
  imageCoverCount: number = 0;
  firstMenuIds: number[] = [];

  getMenu () {
    this.$Api.promotion
      .getMenu()
      .then(result => {
        if (result.ReturnValue && result.ReturnValue.HeaderMenus) {
          result.ReturnValue.HeaderMenus.forEach(item => {
            if (item.Childs && item.Childs.length > 0 && item.Value && item.Value.Id) {
              this.firstMenuIds.push(parseInt(item.Value.Id));
            }
          });
        }
        console.log(this.firstMenuIds, '一級目錄id數組(40108 40109 40110)');
        this.searchCMSByTitle();
      })
      .catch(error => {
        console.log(error);
      });
  }
  searchCMSByTitle () {
    this.$Api.cms.searchCMSByTitle({ Title: this.searchKey, PageSize: this.pageSize, IsMobile: this.isMobile, IsSearchBoby: true }).then(result => {
      if (result.Data && result.Data.length) {
        result.Data.forEach(item => {
          if (item.CustomDateTime && item.CustomDateTime !== null) {
            item.CustomDateTime = item.CustomDateTime.substring(0, item.CustomDateTime.indexOf(' '));
          }
          let isFirstMenu = $.inArray(item.Id, this.firstMenuIds);
          // 属于一级目录 隐藏
          if (isFirstMenu === -1) {
            this.resultList.push(item);
          }
        });
        // 计算返回图片的数量
        this.resultList.forEach((item) => {
          if (item['Cover']) {
            this.imageCoverCount += 1;
          };
        });
      }
      console.log(this.resultList, 'search页搜索结果');
    });
  }

  loadImage () {
    this.loadImageCount += 1;
  }
  waterfall () {
    const lis = $('.result-list li');
    const liW = 410;
    $.each(lis, (index, item) => {
      let liH = Number($(item).outerHeight()) + 30; // 30是margin-bottom
      if (index < 3) {
        this.heightArr[index] = liH;
      } else {
        let minLiH = Math.min.apply(Math.min, this.heightArr);
        let minLiIndex = $.inArray(minLiH, this.heightArr);
        $(item).css({
          position: 'absolute',
          top: minLiH + 'px',
          left: minLiIndex * liW + 'px'
        });
        this.heightArr[minLiIndex] = minLiH + liH;
      }
      let maxLiH = Math.max.apply(Math.max, this.heightArr);
      let maxLiIndex = $.inArray(maxLiH, this.heightArr);
      $('.result-list').height(this.heightArr[maxLiIndex]);
    });
  }
  get isMobile () {
    return this.$store.state.isMobile;
  }
  get searchKey () {
    return this.$route.params.key;
  }
  handleSizeChange (val) {
    console.log(`每页 ${val} 条`);
  }

  handleCurrentChange (val) {
    this.currentPage = val;
    console.log(`当前页: ${val}`);
    this.searchCMSByTitle();
  }
  toUrl (n) {
    if (n.Url) {
      window.location.href = n.Url;
    } else if (n.IdType === 'Category') {
      this.$router.push('/cms/catDetail/' + n.Id);
    } else if (n.IdType === 'Content') {
      this.$router.push('/cms/content/' + n.Id);
    }
  }

  created () {
    this.getMenu();
  }
  @Watch('loadImageCount', { deep: true })
  onImgLoadCountChange () {
    // 当下载图片数量和后台返回图片数量相等时，可执行瀑布流排布
    if (this.loadImageCount === this.imageCoverCount && !this.isMobile) {
      this.waterfall();
    }
  }
  @Watch('searchKey', { deep: true })
  onSearchKeyChange () {
    this.loadImageCount = 0;
    this.imageCoverCount = 0;
    this.resultList = [];
    this.firstMenuIds = [];
    this.getMenu();
  }
  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.loadImageCount = 0;
    this.imageCoverCount = 0;
    this.resultList = [];
    this.firstMenuIds = [];
    this.getMenu();
  }
}

